import request from '@/utils/requestV2'
const qs = require('qs')

// 新增消息发送时间配置
export function saveMessageTime (data) {
  return request({
    url: '/ooh-message/v1/messageconfig/savemessagetime',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取公司下的消息发送时间配置
export function getMessageTime (data) {
  return request({
    url: '/ooh-message/v1/messageconfig/getmessagetime',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取公司下的消息配置
export function getMessageCompany (data) {
  return request({
    url: '/ooh-message/v1/messageconfig/getmessagecompany',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取各类型消息配置
export function getMessageConfigs (data) {
  return request({
    url: '/ooh-message/v1/messageconfig/getmessageconfigs',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 设置消息抄送人
export function updateccUserids (data) {
  return request({
    url: '/ooh-message/v1/messageconfig/updateccuserids',
    method: 'post',
    data: qs.stringify(data)
  })
}
