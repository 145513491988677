<template>
  <div>
    <div class="m-b-10">
      <RadioGroup
        v-model="category"
        type="button"
        size="small"
        @on-change="getMsgTypeData"
      >
        <Radio
          v-for="item in msgTypeArray"
          :key="'msgType_'+item.value"
          :label="item.value"
        >{{item.name}}</Radio>
      </RadioGroup>
    </div>
    <div>
      <Table
        stripe
        size="small"
        :data="dataList"
        :columns="dataColumns"
      ></Table>
    </div>

    <!-- 人员配置 -->
    <user-picker ref="userPickerComponents"></user-picker>

    <!-- 编辑发送时间段 -->
    <Modal
      v-model="sendTimeModal"
      width="400"
    >
      <p
        slot="header"
        class="text-center"
      >发送时间段配置</p>
      <div>

        <Row
          :gutter="8"
          class="p-b-5"
          v-for="(item,index) in companyTimes"
          :key="index"
        >
          <i-col span="22">
            <TimePicker
              v-model="item.timeRange"
              size="small"
              format="HH:mm"
              type="timerange"
              placeholder="选择发送时间段"
              style="width: 220px"
            ></TimePicker>
          </i-col>
          <i-col span="2">
            <a
              href="javascript:void(0);"
              @click="handleRemoveTime(index)"
            >
              <Icon
                type="ios-trash"
                size="16"
                color="red"
              />
            </a>
          </i-col>
        </Row>
        <div class="m-b-10">
          <Button
            type="primary"
            size="small"
            @click="handleAddTime"
            icon="md-add"
          >添加时间段</Button>
        </div>
      </div>
      <div slot="footer">
        <Button
          type="text"
          ghost
          @click="sendTimeModal=false"
        >取消</Button>
        <Button
          type="primary"
          @click="handleSetSendTime"
        >确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import UserPicker from './UserPicker'
import { getMessageConfigs, getMessageTime, saveMessageTime } from '@/api/message/messageconfig'

export default {
  components: {
    UserPicker
  },
  data () {
    return {
      category: 1,
      msgTypeArray: [
        { value: 1, name: '客户' },
        { value: 2, name: '订单' },
        { value: 3, name: '合同' },
        { value: 4, name: '上刊' },
        { value: 5, name: '汇款' },
        { value: 6, name: '其他' }
      ],
      dataList: [],
      dataColumns: [
        { title: '消息名称', align: 'center', key: 'name' },
        { title: '消息描述', align: 'center', key: 'desc' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleUserSeeting(params.row)
                  }
                }
              }, '人员配置'),
              h('a', {
                on: {
                  click: () => {
                    this.selectedConfigId = params.row.id
                    this.handleSendTimeSeeting()
                  }
                }
              }, '发送时间')
            ])
          }
        }
      ],

      selectedConfigId: null,
      sendTimeModal: false,
      companyTimes: [] // 公司配置发送时间
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  },
  mounted () {
    this.getMsgTypeData()
  },
  methods: {
    getMsgTypeData () {
      getMessageConfigs({ category: this.category }).then(res => {
        if (res && !res.errcode) {
          this.dataList = res
        } else {
          this.dataList = []
        }
      })
    },
    handleUserSeeting (params) {
      this.$refs.userPickerComponents.showModal(params)
    },
    handleSendTimeSeeting () {
      // Object.assign(this.$data.companyTimes, this.$options.data().companyTimes)
      // this.companyTimes[0].configId = this.selectedConfigId
      // this.companyTimes[0].companyId = this.companyId
      this.companyTimes = [
        {
          configId: this.selectedConfigId,
          companyId: this.companyId,
          start: '',
          end: '',
          timeRange: []
        }
      ]
      this.sendTimeModal = true
      this.getCompanySendTimeData()
    },
    /**
   * 获取公司发送消息时间段数据
   */
    getCompanySendTimeData () {
      getMessageTime({ companyId: this.companyId, configId: this.selectedConfigId }).then(res => {
        if (res && !res.errcode) {
          this.companyTimes = res.length ? res : this.companyTimes

          this.companyTimes.forEach(item => {
            item.timeRange = [item.start, item.end]
          })
        }
      })
    },
    handleAddTime () {
      const newItem = {
        configId: this.selectedConfigId,
        companyId: this.companyId,
        start: '',
        end: '',
        timeRange: []
      }
      this.companyTimes.push(newItem)
    },
    handleRemoveTime (index) {
      if (this.companyTimes.length > 1) {
        this.companyTimes.splice(index, 1)
      }
    },
    handleSetSendTime () {
      if (this.companyTimes.every(x => x.timeRange[0].length === 0)) {
        this.$Notice.warning({ desc: '请完整填写信息' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定数据无误，并提交？',
        onOk: () => {
          const messageTimes = this.companyTimes.map(item => {
            return {
              configId: item.configId,
              companyId: item.companyId,
              start: item.timeRange[0],
              end: item.timeRange[1]
            }
          })
          const postData = {
            companyId: this.companyId,
            configId: this.selectedConfigId,
            messageTimes: JSON.stringify(messageTimes)
          }
          saveMessageTime(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.sendTimeModal = false
            }
          })
        }
      })
    }
  }
}
</script>
