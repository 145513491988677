<template>
  <Modal v-model="userSettingModal" width="1000" footer-hide>
      <p slot="header">
        <span class="workplatform-title">
          <span class="text-orange">{{ modalTitle }}</span> 消息抄送配置
        </span>
      </p>
      <Row :gutter="8" class="m-b-10">
        <i-col flex="100px" class="p-t-5">
          <span class="title">消息所属主体</span>
        </i-col>
        <i-col flex="auto">
          <Select v-model="configCompanyId" style="width: 300px;" @on-change="handleChangeCompany">
            <Option v-for="item in allowCompanys" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>

          <Button type="success" class="m-l-10" @click="handleSubmit">保存配置</Button>
        </i-col>
      </Row>

      <Row :gutter="8" class="m-b-10">
        <i-col flex="100px">
          <span class="title">消息抄送用户</span>
        </i-col>
        <i-col flex="auto">
          <p v-for="(company,index) in ccCompanys" :key="index" :class="index>0?'m-t-5':''">
            【{{ company.companyName }}】 用户<br/>
            <Tag
              v-for="item in userList.filter(x=>x.companyId===company.companyId)"
              :key="item.id"
              :name="item.id"
              color="primary"
              closable
              @on-close="handleDelUser"
            >{{item.name}}</Tag>
          </p>
        </i-col>
      </Row>

      <Divider>请在下方表格中设置消息抄送用户</Divider>

      <Row :gutter="8" class="p-b-10">
        <i-col span="6">
          <Select v-model="query.companyId" size="small">
            <Option v-for="item in allowCompanys" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </i-col>
        <i-col span="14">
          <i-input
            size="small"
            v-model="query.keyword"
            placeholder="关键字：用户名、姓名等"
          ></i-input>
        </i-col>
        <i-col span="4">
          <Button
            size="small"
            type="primary"
            icon="ios-search"
            @click="searchHeadle"
          >搜索</Button>
        </i-col>
      </Row>
      <i-table
        stripe
        border
        size="small"
        :data="list"
        :columns="columns"
      ></i-table>
      <div class="paging_style">
        <Page
          size="small"
          :total="total"
          :page-size="query.pageSize"
          show-total
          show-elevator
          :current="query.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>

      <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>配置加载中...</div>
      </Spin>
  </Modal>
</template>

<script>
import { getCompanyChild } from '@/api/os/company'
import { getUserPage } from '@/api/os/user'
import { getMessageCompany, updateccUserids } from '@/api/message/messageconfig'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      configCompanyId: null,
      userSettingModal: false,
      selectedConfigBean: null,
      modalTitle: '',
      spinShow: false,

      allowCompanys: [], // 允许查询的公司清单
      ccCompanys: [],
      userList: [],

      total: 0,
      query: {
        keyword: '',
        companyId: 0,
        pageSize: 10,
        pageNumber: 1
      },
      list: [],
      columns: [
        { title: '用户名', key: 'username', width: 120, align: 'center' },
        { title: '姓名', key: 'name', width: 120, align: 'center' },
        {
          title: '工作台角色',
          align: 'center',
          render: (h, parmas) => {
            const roles = []
            parmas.row.stationRole.forEach(item => {
              roles.push(h('Tag', {
                props: {
                  color: 'blue'
                }
              }, item.name))
            })
            return h('div', roles)
          }
        },
        {
          title: '操作',
          width: 100,
          render: (h, data) => {
            if (this.userList.some(x => x.id === data.row.id)) {
              return h('a', {
                style: {
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    // this.$emit('removeUser', data.row)
                    this.handleEditUser(data.row)
                  }
                }
              }, '移除')
            } else {
              return h('a', {
                on: {
                  click: () => {
                    // this.$emit('addUser', data.row)
                    this.handleEditUser(data.row)
                  }
                }
              }, '添加')
            }
          }
        }
      ]
    }
  },
  mounted () {
    this.loadAllowCompanys()
  },
  methods: {
    showModal (params) {
      this.selectedConfigBean = params
      this.modalTitle = params.name
      this.configCompanyId = this.publisherId
      this.userSettingModal = true

      this.handleChangeCompany()
    },
    loadAllowCompanys () {
      const postData = {
        type: 1,
        enabled: true,
        companyId: this.publisherId
      }

      getCompanyChild(postData).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }

        this.allowCompanys = array
        this.query.companyId = array.length > 0 ? array[0].id : this.publisherId
      })
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    handleChangeCompany () {
      this.spinShow = true
      // 获取当前消息类别已设置用户
      const postData = {
        companyId: this.configCompanyId,
        publisherId: this.publisherId,
        configId: this.selectedConfigBean.id
      }
      getMessageCompany(postData).then(res => {
        if (res && !res.errcode) {
          this.userList = res[0].userVOS.map(x => {
            return {
              id: x.userId,
              name: x.name,
              companyId: x.companyId
            }
          })
          this.getGroupCompany()
        } else {
          this.userList = []
          this.ccCompanys = []
        }

        this.spinShow = false
      })
    },
    getGroupCompany () {
      const groupCompany = []
      this.userList.forEach(element => {
        if (!groupCompany.find(x => x.companyId === element.companyId)) {
          groupCompany.push({
            companyId: element.companyId,
            companyName: this.allowCompanys.find(x => x.id === element.companyId).name
          })
        }
      })

      this.ccCompanys = groupCompany
    },
    checkChildCompanys (companyChildrenBean, levelIndex) {
      for (let index = 0; index < companyChildrenBean.length; index++) {
        const checkLevelIndex = [].concat(levelIndex)
        if (companyChildrenBean[index].companyId === this.companyId) {
          checkLevelIndex.push(index)
          return checkLevelIndex
        }

        checkLevelIndex[0] += 1
        checkLevelIndex.push(index)
        const newVal = this.checkChildCompanys(companyChildrenBean[index].children, checkLevelIndex)

        if (newVal) {
          return newVal
        }
      }
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initUserPage()
    },
    initUserPage () {
      getUserPage(this.query).then(data => {
        this.list = data.list
        this.total = data.totalRow
      })
    },
    searchHeadle () {
      this.query.pageNumber = 1
      this.initUserPage()
    },
    handleEditUser (data) {
      if (!this.userList.some(x => x.id === data.id)) {
        this.userList.push({
          id: data.id,
          name: data.name,
          companyId: this.query.companyId
        })
      } else {
        const delIndex = this.userList.findIndex(x => x.id === data.id)
        this.userList.splice(delIndex, 1)
      }

      this.getGroupCompany()
    },
    handleDelUser (event, name) {
      const delIndex = this.userList.findIndex(x => x.id === parseInt(name))
      this.userList.splice(delIndex, 1)
      this.getGroupCompany()
    },
    handleSubmit () {
      const postData = {
        ccUserIds: JSON.stringify(this.userList.map(x => x.id)),
        companyId: this.configCompanyId,
        publisherId: this.publisherId,
        configId: this.selectedConfigBean.id
      }
      updateccUserids(postData).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '配置保存成功！' })
        }
      })
    }
  },
  watch: {
    'query.companyId' (val) {
      this.searchHeadle()
    }
  }
}
</script>
